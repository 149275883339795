
import axios from 'axios';
export default {
    
        async createCustomerAddresses(params)  {
            return await axios.post(`customer_addresses/create` , params)
        },
        async createCustomerAddressesList(params)  {
            return await axios.post(`customer_addresses/create/list` , params)
        },
        async updateCustomerAddressesColumn(column , value , data)  {
            return await axios.put(`customer_addresses/update_list?${column}=${value}` , data)
        },
        async deleteCustomerAddressesList(list)  {
            return await axios.delete(`customer_addresses/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportCustomerAddresses(column , value)  {
            return await axios.get(`customer_addresses/report?${column}=${value}`)
        },
        async getAllCustomerAddresses()  {
            return await axios.get(`customer_addresses/all`)
        },
        async getOneCustomerAddresses(customer_address_id)  {
            return await axios.get(`customer_addresses/all/${customer_address_id}`)
        },
        async getCustomerAddressesByColumn(column , value)  {
            return await axios.get(`customer_addresses/filter?column=${column}&value=${value}`)
        },
        async deleteCustomerAddresses(customer_address_id)  {
            return await axios.delete(`customer_addresses/delete/${customer_address_id}`)
        },
        async updateCustomerAddresses(customer_address_id , params)  {
            return await axios.put(`customer_addresses/update/${customer_address_id}` , params)
        },
}
        